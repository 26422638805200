<template>
    <section class="live-video">
        <ul class="live-video__list">
            <li  class="live-video__items">
                <div class="live-video__show-video">
                    <div class="live-video__item">
<!--                        <UnitsList_unit_status_icon :status="videoStatus" :position="'tl'" v-if="false" />-->
                        <!--{{videoStatus}}-->
                    </div>
                    <ul class="live-video__show-container" v-if="cams.length">
                        <li class="live-video__item" v-for="cam in cams" :key="cam.id">
                            <label class="live-video__label">
                                <input class="live-video__hidden-input" type="checkbox" :name="'cam'+cam.id" v-model="cam.view">
                                <div class="live-video__title">{{ cam.name }}</div>
                            </label>
                        </li>
                    </ul>
                    <div class="live-video__item" v-if="cams.length">
                        <button class="live-video__show-all" @click="setShowALL">
                            Show All
                        </button>
                    </div>
                </div>
                <div class="live-video__live" v-if="cams.length">Live Video</div>
                <div class="live-video__play" v-if="cams.length"
                     @click="_showLiveVideo"
                >
                    <img src="@/assets/image/video-play.png" alt="">
                </div>
            </li>
        </ul>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Unit_liveVideo_control",
    props:{
        'cams':{
            type: Array,
            default: () => { return [] }
        },
        'camsActive':{
            type: Array,
            default: () => { return null }
        },
        'videoStatus': {
            type: String
        },
        'showLiveVideo': {
            type: Boolean,
            default: () => { return false }
        },
        'isShowMap': Boolean,
    },
    computed:{
        ...mapGetters([
           "getUnitActive",
        ]),
        // cameras:{
        //     get(){ return this.cams },
        //     set(val){ this.$emit('update',val) }
        // },
        showVideo:{
            get(){ return this.showLiveVideo },
            set(val){ this.$emit('update', val) }
        }
    },
    methods:{
        setShowALL(){
            this.cams.forEach(e => e.view = true)
        },
        _showLiveVideo(){
            if(!this.camsActive) return false;
            this.showVideo = true;
            this.$emit('update:isShowMap',false)
            // let cams
            // await cams = this.getUnitLiveVideo(this.getUnitActive)
            this.$api.units.liveVideo(this.getUnitActive, {}, {timeout: 60000})
                .then((res) => {
                    console.log(res)
                    let cams = (res.data || {}).channels || []
                    this.cams = this.cams.map(cam => {
                        let _cam = cams.find(c => c.id == cam.id)
                        return {..._cam, ...cam}
                    })
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }
}
</script>

<style scoped>

</style>